import { contactSubmit } from './contact.js';
import { mobileToggle } from './header.js';
import { photoCaro } from './photo_gallery.js';
import { initMap } from './map.js';
import { facilityCaro } from './facility.js';

if (document.getElementById('site_mobile_toggle')) {
	mobileToggle();
}

photoCaro.init();

if (document.querySelector('#map')) {
	initMap();
}

facilityCaro.init();

if (document.getElementById('facility_rates')) {
	/* eslint-disable no-undef */
	/* eslint-disable consistent-return */
	/* eslint-disable eqeqeq */
	let subIP = false;
	if (document.getElementById('request_info')) {
		document.getElementById('request_info').addEventListener('submit', function (e) {
			if (document.getElementById('hp').value != '') return false;
	
			Array.prototype.forEach.call(document.querySelectorAll('#request_info .error'), function (error) {
				error.classList.remove('error');
			});
			document.querySelector('.response_message').innerHTML = '';
	
			if (subIP) {
				return;
			}
	
			let checkFields = new Array('name', 'email', 'message');
			let errorsFound = new Array();
			checkFields.forEach(function (item) {
				if (document.getElementById(item + '_input').value == '') {
					document.getElementById(item + '_input').classList.add('error');
					errorsFound.push(item);
				}
			});
			if (!subIP && errorsFound.length == 0) {
				subIP = true;
				let request = new XMLHttpRequest(),
					method = 'POST',
					url = document.getElementById('request_info').getAttribute('action'),
					data = new FormData(document.getElementById('request_info'));
	
				request.onreadystatechange = function () {
					if (request.readyState === 4 && request.status === 200) {
						let response = JSON.parse(request.responseText);
						if (response.ret_det.success) {
							let input = document.getElementById('request_form_submit');
							input.parentElement.removeChild(input);
							document.querySelector('.response_message').innerHTML = "<span class='success'>" + response.ret_det.message + "</span>";
							dataLayer.push({'DLVSuccess': 'true', 
								'event': 'Contact Form Submission'
							});
						} else {
							if (typeof response.data.error_fields !== 'undefined') {
								let errors = [];
								for (let x = 0; x < response.data.error_fields.length; x++) {
									errors.push(response.data.error_fields[x].field_name);
								}
								updateResponseMessage(errors);
							} else {
								document.querySelector('.response_message').innerHTML = "<span class='error'>" + response.ret_det.message + "</span>";
							}
						}
						subIP = false;
					}
				};
				request.open(method, url, true);
				request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
				request.send(data);
			}
			else if (errorsFound.length > 0) {
				updateResponseMessage(errorsFound);
			}
			else {
				document.querySelector('.response_message').innerHTML = "<span class='error'>There was a problem processing your request.</span>";
			}
			e.stopImmediatePropagation();
			e.preventDefault();
		});
	}

	let overlay;
	overlay = document.getElementById('rates_overlay');
	
	document.getElementById('rates_table').addEventListener('click', function(e) {
		if (e.target.classList.contains('form_toggle')) {
			overlay.classList.add('show');
			document.getElementById('rates_overlay').scrollIntoView();
			document.querySelector('#unit_input').value = e.target.dataset.size;
			document.querySelector('#unit_type_input').value = e.target.dataset.unittype;
		}
		if (e.target.id === 'rates_overlay_close' || e.target.id === 'rates_overlay') {
			overlay.classList.remove('show');
		}
	});
}

function updateResponseMessage(errors) {
	let response = '';
	if (errors.length == 1) {
		response = "The " + errors[0] + " field is required.";
	} else if (errors.length == 2) {
		response = "The " + errors[0] + " and " + errors[1] + " fields are required.";
	} else {
		let fields = "";
		for (let x = 0; x < errors.length; x++) {
			fields += errors[x];
			if (x < errors.length - 1) {
				fields += ', ';
			}
			if (x == errors.length - 2) {
				fields += 'and ';
			}
		}
		response = "The " + fields + " fields are required.";
	}
	document.querySelector('.response_message').innerHTML = "<span class='error'>" + response + "</span>";
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}
